import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './auth/authguard.service';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { EmailMessage } from 'src/dto/EmailMessage';

@Injectable({
  providedIn: 'root'
})

export class AppService {

  rootURL = 'http://127.0.0.1:8887';
  _apiUrl = 'default';

  constructor(private http: HttpClient) {
    this._apiUrl = environment.apiUrl;
  }

  // Auth and Session /////////////////////////////////////

  validateSAMLart(TARGET:string, SAMLart:string, updateSecret: boolean)
  {
    return this.http.get<any>(`${this._apiUrl}/auth/GetClaimsDataFromArtifact?Artid=${SAMLart}&targetUrl=${TARGET}&updateSecret=${updateSecret}`)
  }

  // Acx APIs /////////////////////////////////////////

  getLazyUserCreationRedirection(opRef: string, opSite: string, opTarget: string) {
    return this.http.get<any>(`${this._apiUrl}/acx/lazyusercreation?opRef=${opRef}&opSite=${opSite}&opTarget=${opTarget}`);
  }

  addLazyUser(formData: FormData, opRef: string, opSite: string, opTarget: string)
  {
    return this.http.post<any>(`${this._apiUrl}/acx/lazyusercreation?opRef=${opRef}&opSite=${opSite}&opTarget=${opTarget}`, formData);
  }
  
  // Logs APIs ////////////////////////////////////////////

  getLogs(limit: number=10, offset: number=0, logId: number=0, logVal:string,sortDirection: string)
  {
    return this.http.get<any>(`${this._apiUrl}/logs?limit=${limit}&offset=${offset}&logId=${logId}&logVal=${logVal}&sortDirection=${sortDirection}`);
  }

  // Email APIs ////////////////////////////////////////////

  sendEmail(emailMessage: EmailMessage)
  {
    return this.http.post<any>(`${this._apiUrl}/email`, emailMessage);
  }
  
  // User APIs ////////////////////////////////////////////

  getUsers(limit: number, offset: number, searchText: string, cId: number, subgroupId: number, type: string, country: string, isActive: boolean | null, emailSent: boolean | null, sortBy: string, sortDirection: string)
  {
    return this.http.get<any>(`${this._apiUrl}/oktauserprofile?limit=${limit}&offset=${offset}&searchText=${searchText}&cId=${cId}&subgroupId=${subgroupId}&type=${type}&country=${country}&isActive=${isActive}&emailSent=${emailSent}&sortBy=${sortBy}&sortDirection=${sortDirection}`);
  }

  getUsersCsv(searchText: string, cId: number, subgroupId: number, type: string, country: string, isActive: boolean | null, emailSent: boolean | null, sortBy: string, sortDirection: string)
  {
    return this.http.get(`${this._apiUrl}/oktauserprofile/GetOktaUserProfilesCSV?searchText=${searchText}&cId=${cId}&subgroupId=${subgroupId}&type=${type}&country=${country}&isActive=${isActive}&emailSent=${emailSent}&sortBy=${sortBy}&sortDirection=${sortDirection}`, { responseType: 'text' });
  }

  addUser(formData: FormData, sendEmail: boolean)
  {
    formData.append('UserName', "dummy");
    return this.http.post<any>(`${this._apiUrl}/application/1/CreateOktaUserProfile?sendEmail=${sendEmail}`, formData);
  }

  getUserOktaStatus(email:string, refresh: boolean)
  {
    return this.http.get(`${this._apiUrl}/oktauserprofile/${email}/status?refresh=${refresh}`, { responseType: 'text' });
  }

  updateOktaUserProfile(formData: FormData)
  {
    return this.http.put<any>(this._apiUrl+'/oktauserprofile?appId=1', formData);
  }

  updateUserClientAndGroups(userId: number, clientId: string, groupIdsCsv: string, isClientContact: boolean)
  {
    return this.http.post<any>(`${this._apiUrl}/oktauserprofile/${userId}/updateClientAndGroups?cId=${clientId}&groupIdsCsv=${groupIdsCsv}&isClientContact=${isClientContact}`, '');
  }

  deleteUserClient(userId: number, cId: number)
  {
    return this.http.post<any>(`${this._apiUrl}/oktauserprofile/${userId}/deleteClient?cId=${cId}`, '');
  }

  getUserByEmail(email:string)
  {
    return this.http.get<any>(`${this._apiUrl}/oktauserprofile/${email}`);
  }

  sendActivationEmail(email : string, isBulk : boolean = false)
  {
    return this.http.post<any>(`${this._apiUrl}/oktauserprofile/${email}/reactivate?appId=1&isBulk=${isBulk}`, ``);
  }

  resetOktaAuthenticators(email:string)
  {
    const requestOptions: Object = { responseType: 'text' }
    return this.http.post<any>(`${this._apiUrl}/oktauserprofile/${email}/resetAuthenticators?appId=1`, ``, requestOptions);
  }

  setIsActive(email:string, isActive:boolean)
  {
    const requestOptions: Object = { responseType: 'text' }
    return this.http.post<any>(`${this._apiUrl}/oktauserprofile/${email}/setIsActive?isActive=${isActive}`, ``, requestOptions);
  }

  getUserById(id:number)
  {
    return this.http.get<any>(`${this._apiUrl}/oktauserprofile/${id}`);
  }

  getOktaDetailsByEmail(email:string)
  {
    const requestOptions: Object = { responseType: 'json' }
    return this.http.post<any>(`${this._apiUrl}/oktauserprofile/${email}/getoktauser?appId=1`, ``, requestOptions);
  }

  getUserClients(userId: number){
    return this.http.get<any>(`${this._apiUrl}/oktauserprofile/${userId}/clients`);
  }

  getUserGroupsAndTools(userId: number){
    return this.http.get<any>(`${this._apiUrl}/oktauserprofile/${userId}/GroupsAndTools`);
  }


  // Client APIs ////////////////////////////////////////////

  getClientById(id:number)
  {
    return this.http.get<any>(`${this._apiUrl}/client/${id}`);
  }

  addClient(formData: FormData)
  {
    formData.append('ClientId', "dummy");
    return this.http.post<any>(`${this._apiUrl}/Client`, formData);
  }

  updateClient(formData: FormData)
  {
    formData.append('ClientId', "dummy");
    return this.http.put<any>(`${this._apiUrl}/Client`, formData);
  }

  deleteClient(id:number)
  {
    return this.http.delete<any>(`${this._apiUrl}/client/${id}`);
  }

  searchClients(limit: number = 10, offset: number = 0, searchText: string = '',  region: string = '', countryCode: string = '', industry: string = '', clientType: string = '', isDeleted: boolean | null = null, sortBy: string = 'name', sortDirection: string = 'asc', showAll: boolean = false)
  {
    return this.http.get<any>(`${this._apiUrl}/client/search?limit=${limit}&offset=${offset}&searchText=${searchText}&region=${region}&countryCode=${countryCode}&industry=${industry}&clientType=${clientType}&isDeleted=${isDeleted}&sortBy=${sortBy}&sortDirection=${sortDirection}&showAll=${showAll}`);
  }

  ClientUsersSearch(limit: number = 10, offset: number = 0,  countryCode: string = '',  sortDirection: string = 'asc')
  {
    return this.http.get<any>(`${this._apiUrl}/client/cucsearch?limit=${limit}&offset=${offset}&sortDirection=${sortDirection}&countryCode=${countryCode}`);
  }
  
  ClientUsersSearchCSV(countryCode: string = '',  sortDirection: string = 'asc')
  {
    return this.http.get(`${this._apiUrl}/client/GetClientUserCSV?sortDirection=${sortDirection}&countryCode=${countryCode}`, { responseType: 'text' });
  }

  getClientGroups(cId: number) {
    return this.http.get<any>(`${this._apiUrl}/client/${cId}/groups`);
  }

  checkIfClientExists(name: string)
  {
   return this.http.get<any>(`${this._apiUrl}/client/exists?name=${name}`);
  }

  getClientsForAdmin(idsCsv: string){
    return this.http.get<any>(`${this._apiUrl}/client/getClientsForAdmin?idsCsv=${idsCsv}`);
  }

  getClientsByCountryCodes(countryCodes:string) {
    return this.http.get<any>(`${this._apiUrl}/client/getbycountrycodes?countryCodes=${countryCodes}`);
  }
  
  getClientsCsv(loggedInUser: LoggedInUser, searchText: string, region: string = '', countryCode: string,industry: string = '', clientType: string = '', isDeleted: boolean | null = null, sortBy: string, sortDirection: string)
  {
    return this.http.get(`${this._apiUrl}/client/GetClientsCSV?searchText=${searchText}&region=${region}&countryCode=${countryCode}&industry=${industry}&clientType=${clientType}&isDeleted=${isDeleted}&sortBy=${sortBy}&sortDirection=${sortDirection}`, { responseType: 'text' });
  }

  // Country APIs //////////////////////////////////////////

  getCountries(loggedInUser: LoggedInUser) {
    return this.http.get<any>(`${this._apiUrl}/country`);
  }

  searchCountries(limit: number = 10, offset: number = 0, searchText: string = '', sortDirection: string = 'asc', showAll: boolean = false)
  {
   return this.http.get<any>(`${this._apiUrl}/country/search?limit=${limit}&offset=${offset}&searchText=${searchText}&sortDirection=${sortDirection}&showAll=${showAll}`);
  }

  mapTools(id:number, body:any)
  {
    return this.http.post<any>(`${this._apiUrl}/country/${id}/resetCountryTool`, body);
  }

  getCountryTools(loggedInUser: LoggedInUser, countryId: number) {
    return this.http.get<any>(`${this._apiUrl}/country/${countryId}/tools`);
  }

  // Industry APIs /////////////////////////////////////////

  getIndustries(loggedInUser: LoggedInUser)
  {
    return this.http.get<any>(`${this._apiUrl}/industry`);
  }

  // Tool APIs /////////////////////////////////////////////

  searchTools(limit: number = 10, offset: number = 0, searchText: string = '', countryId: number = 0, sortDirection: string = 'asc')
  {
   return this.http.get<any>(`${this._apiUrl}/tools/search?limit=${limit}&offset=${offset}&searchText=${searchText}&countryId=${countryId}&sortDirection=${sortDirection}`);
  }

  getUserClientGroups(userId: number)
  {
    return this.http.get<any>(`${this._apiUrl}/toolsgroup/users/${userId}`);
  }

  getApps(loggedInUser: LoggedInUser) {
    return this.http.get<any>(`${this._apiUrl}/tools`);
  }

  addSubgroup(body:any){
    return this.http.post<any>(`${this._apiUrl}/toolsgroup`, body);
  }

  deleteSubgroup(gId: number){
    return this.http.delete<any>(`${this._apiUrl}/toolsgroup?gId=${gId}`);
  }

  getUserTools(userId: number){
    return this.http.get<any>(`${this._apiUrl}/tools/users/${userId}`);
  }

  getSubgroupMemberCount(id: number){
    return this.http.get<any>(`${this._apiUrl}/toolsgroup/${id}/users/count`);
  }

  // Admin APIs ////////////////////////////////////////////

  getAdmins(limit: number = 10, offset: number = 0, searchText: string = '', countryId: number = 0, sortDirection: string = 'asc') {
    return this.http.get<any>(`${this._apiUrl}/admin?limit=${limit}&offset=${offset}&searchText=${searchText}&countryId=${countryId}&sortDirection=${sortDirection}`);
  }
  getAdminsReport(limit: number = 10, offset: number = 0, searchText: string = '', countryCode: string = '', sortDirection: string = 'asc') {
    return this.http.get<any>(`${this._apiUrl}/admin/GetAdminReport?limit=${limit}&offset=${offset}&searchText=${searchText}&countryCode=${countryCode}&sortDirection=${sortDirection}`);
  }
  getAdminsCSV(searchText: string = '', countryCode: string = '', sortDirection: string = 'asc')
  {
    return this.http.get(`${this._apiUrl}/admin/GetAdminsCSV?searchText=${searchText}&countryCode=${countryCode}&sortDirection=${sortDirection}`, { responseType: 'text' });
  }
  getAdminById(id:number) {
    return this.http.get<any>(`${this._apiUrl}/admin/${id}`);
  }
  getAdminByEmail(email:string | null) {
    return this.http.get<any>(`${this._apiUrl}/admin/${email}`);
  }
  addAdmin(adminUser: any){
    adminUser.Username = "dummy";
    return this.http.post<any>(`${this._apiUrl}/admin`, adminUser);
  }
  updateAdmin(adminUser: any){
    adminUser.Username = "dummy"
    return this.http.put<any>(`${this._apiUrl}/admin`, adminUser);
  }
  setAdminIsActive(email:string, isActive:boolean)
  {
    const requestOptions: Object = { responseType: 'text' }
    return this.http.post<any>(`${this._apiUrl}/admin/${email}/setIsActive?isActive=${isActive}`, ``, requestOptions);
  }

  // Sharepoint Tasks /////////////////////////////////////////

  getSharepointTasksByEntityId(entityId: string, status: string | null)
  {
    return this.http.get<any>(`${this._apiUrl}/sharepointtask/${entityId}?status=${status}`);
  }

  addSharepointTask(sharepointTask: any)
  {
    return this.http.post<any>(`${this._apiUrl}/sharepointtask`, sharepointTask);
  }

  getSharepointTask(limit: number = 10, offset: number = 0, searchTask: string = '', searchUser: string = '',searchEntityId: string = '', start:string = '', end:string = '', sortDirection: string = 'desc'){
    return this.http.get<any>(`${this._apiUrl}/sharepointtask?limit=${limit}&offset=${offset}&sortDirection=${sortDirection}&searchUser=${searchUser}&searchTask=${searchTask}&start=${start}&end=${end}&searchEntityId=${searchEntityId}`);
  }
  getSharepointTaskCSV(searchTask: string = '', searchUser: string = '',searchEntityId: string = '', start:string = '', end:string = '', sortDirection: string = 'desc'){
    return this.http.get(`${this._apiUrl}/sharepointtask/GetSharePointTasksCSV?sortDirection=${sortDirection}&searchUser=${searchUser}&searchTask=${searchTask}&start=${start}&end=${end}&searchEntityId=${searchEntityId}`, { responseType: 'text' });
  }
  // AuditLog Tasks /////////////////////////////////////////

  getAuditLogs(limit: number = 10, offset: number = 0, entity: string = '', entityId: string = '', actionsCsv: string = '', sortDirection: string = 'desc') {
    return this.http.get<any>(`${this._apiUrl}/auditlog?limit=${limit}&offset=${offset}&entity=${entity}&entityId=${entityId}&actionsCsv=${actionsCsv}&sortDirection=${sortDirection}`);
  }
  
  // Claim Managers APIs

  getClaimManagersByClientId(cId: number | null) {
    return this.http.get<any>(`${this._apiUrl}/ClaimManager/ClaimManagerForClient?cId=${cId}`);
  }

  updateClaimManagers(cId: number, primarySpocEmail: string, secondarySpocEmail: string)
  {
    return this.http.post<any>(`${this._apiUrl}/ClaimManager/ClaimManagersForClient?cId=${cId}&primarySpocEmail=${primarySpocEmail}&secondarySpocEmail=${secondarySpocEmail}`, '');
  }

  //Client Contact APIs
  getUserClientContact(clientId: number | null , userId: number | null)
  {
    return this.http.get<any>(`${this._apiUrl}/client/clientContact?clientId=${clientId}&userId=${userId}`);
  }

  //Client Sites APIs

  addSite(formData: FormData)
  {
    return this.http.post<any>(`${this._apiUrl}/site`, formData);
  }

  getSites(limit: number, offset: number, searchText: string, cId: number, isActive: boolean | null, sortBy: string, sortDirection: string)
  {
    return this.http.get<any>(`${this._apiUrl}/site?limit=${limit}&offset=${offset}&searchText=${searchText}&cId=${cId}&isActive=${isActive}&sortBy=${sortBy}&sortDirection=${sortDirection}`);
  }

  getSiteById(id:number)
  {
    return this.http.get<any>(`${this._apiUrl}/site/${id}`);
  }

  getSiteByName(cId: number, name:string)
  {
    return this.http.get<any>(`${this._apiUrl}/site/${name}?cId=${cId}`);
  }

  updateSite(formData: FormData)
  {
    return this.http.put<any>(`${this._apiUrl}/Site`, formData);
  }

  deleteSite(id:number)
  {
    return this.http.delete<any>(`${this._apiUrl}/site/${id}`);
  }

  getSiteManagers(siteId: number, limit: number, offset: number, searchText: string, isActive: boolean | null, sortBy: string, sortDirection: string)
  {
    return this.http.get<any>(`${this._apiUrl}/site/${siteId}/managers?limit=${limit}&offset=${offset}&searchText=${searchText}&isActive=${isActive}&sortBy=${sortBy}&sortDirection=${sortDirection}`);
  }

  addSiteManager(formData: FormData)
  {
    return this.http.post<any>(`${this._apiUrl}/site/manager`, formData);
  }

  deleteSiteManager(siteId:number, aonlineUserId: number)
  {
    return this.http.delete<any>(`${this._apiUrl}/site/manager?siteId=${siteId}&aonlineUserId=${aonlineUserId}`);
  }

  getSitePolicies(siteId: number, limit: number, offset: number, searchText: string, isActive: boolean | null)
  {
    return this.http.get<any>(`${this._apiUrl}/site/${siteId}/policies?limit=${limit}&offset=${offset}&searchText=${searchText}&isActive=${isActive}`);
  }

  addSitePolicy(formData: FormData)
  {
    return this.http.post<any>(`${this._apiUrl}/site/policy`, formData);
  }

  deleteSitePolicy(id:number)
  {
    return this.http.delete<any>(`${this._apiUrl}/site/policy/${id}`);
  }

  getClientPoliciesFromOds(cId: number)
  {
    return this.http.get<any>(`${this._apiUrl}/site/agreements?cId=${cId}`);
  }
}
