import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoggedInUser } from 'src/models/LoggedInUser';
import { AuthGuard } from '../auth/authguard.service';
import { OPTION_CLIENT_TYPES } from 'src/models/Constants';
import { Router, ActivatedRoute } from '@angular/router';
import { filter, distinctUntilChanged, debounceTime, tap, switchMap, finalize, catchError, forkJoin } from 'rxjs';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.scss']
})



export class EditClientComponent implements OnInit {
  
  optionClientTypes: any[] = OPTION_CLIENT_TYPES;

  loggedInUser : LoggedInUser;
  isSuperAdmin:boolean;
  minLengthTerm = 2;

  TOOLCODE_COLLABORATION : string = "app-collaboration";
  TOOLCODE_CLAIMS : string = "app-claims";
  TOOLCODE_REALESTATE : string = "app-re-collab";
  TOOLCODE_REQUESTQUOTE : string = "app-re-request-quote";

  id: number | null;
  client: any;
  isLoading: boolean = false;

  editForm = new FormGroup({
    country: new FormControl({value: "", disabled: true}, [Validators.required, Validators.email]),
    name: new FormControl('', [Validators.required]),
    type: new FormControl('', [Validators.required]),
    industry: new FormControl('', [Validators.required]),
    tools: new FormControl(''),
    spUrl: new FormControl(''),
    odsClientIds: new FormControl(''),
    primaryclaimspocemail: new FormControl(''),
    secondaryclaimspocemail: new FormControl('')
  });

  createSubgroupFormDirective: FormGroupDirective;
  createSubgroupForm = new FormGroup({
    tool: new FormControl('', [Validators.required])
  });
  
  @ViewChild('inputFile') fileInputElement: ElementRef;
  file: File | null = null; // Variable to store file
  fileErr: string = "";
  imagePath: string;

  // For Country selection
  filteredCountries: any;
  isLoadingCountries = false;
  selectedCountry: any = null;

  // For existing client navigation
  clientExists: boolean = false;
  canEditExistingClient: boolean = false;
  existingClientId: number = 0;

  //For Industries selection
  allIndustries: any = [];
  selectedIndustry: any = null;

  // For Tools Selection
  allTools: any;
  existingDefaultToolCodes: [string?] = [];
  selectedDefaultTools: any = [];
  potentialDefaultTools: any = [];

  subgroups: any = [];
  existingSubgroupToolCodes: [string?] = [];
  selectedSubgroupTool: any;
  potentialSubgroupTools: any = [];

  noTools = false;

  isCollabToolSelectedDefault = false;
  isCollabToolSelectedSubgroup = false;

  isRealEstateToolSelectedDefault = false;
  isRealEstateToolSelectedSubgroup = false;

  isRequestQuoteToolSelectedDefault = false;
  isRequestQuoteToolSelectedSubgroup = false;

  isClaimsToolSelectedDefault = false;
  isClaimsToolSelectedSubgroup = false;

  //Permissions
  pCreateSpSite: boolean = false;
  createSpSite: boolean = false;
  spSiteGetAccessLink: string | null = null;
  spSiteUrl: string | null = null;

  //For Claim Contact
  selectedPrimaryClaimSpocFName:string;
  selectedPrimaryClaimSpocLName:string;
  selectedSecondaryClaimSpocFName:string;
  selectedSecondaryClaimSpocLName:string;

  //Page State
  showingNewFieldsForSubgroupSelection: boolean = false;

  constructor(private appService: AppService, private router: Router, private _snackBar: MatSnackBar, private authGuard: AuthGuard, private location: Location, private route: ActivatedRoute) {
    this.loggedInUser = authGuard.loggedInUser;
    this.authGuard.loggedInUserUpdated.subscribe(loggedInUserNew => {
      this.loggedInUser = loggedInUserNew;
    });
  }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.id = parseInt(this.route.snapshot.paramMap.get('id')!); 
    this.findClient();

    this.appService.getIndustries(this.loggedInUser).subscribe((response) => {
      this.allIndustries = response;
      this.updateBasicClientFields();
    });

    this.appService.getAdminById(this.loggedInUser.id!).subscribe((response:any) => {
      this.pCreateSpSite = response.pCreateSpSite;
      this.isSuperAdmin = response.roleId == 0;
    });
  }

  ngAfterViewInit() {    
    this.editForm.controls['name'].valueChanges
      .pipe(
        filter(res => {
          this.editForm.controls['name'].setErrors(this.editForm.controls['name'].errors);
          return res !== null && res.length >= this.minLengthTerm
        }),
        distinctUntilChanged(),
        debounceTime(400),
        tap(() => {
          this.isLoading = true;
        }),
        switchMap(value =>  this.appService.checkIfClientExists(value!)
          .pipe(
            catchError(error => {
              this.editForm.controls['name'].setErrors(null);
              return "";
            }),
            finalize(() => {
              this.isLoading = false
            }),
          )
        )
      )
      .subscribe((response: any) => {
        this.isLoading = false;
        if(response != null){
          this.clientExists = response.exists;
          this.canEditExistingClient = response.canEdit;
          this.existingClientId = response.id;
          if(this.clientExists == true && this.existingClientId != this.id){
            this.editForm.controls['name'].setErrors({'exists': true});
          }else{
            this.editForm.controls['name'].setErrors(this.editForm.controls['name'].errors);
          }
        }else{
          this.clientExists = false;
          this.canEditExistingClient = false;
          this.existingClientId = 0;
          this.editForm.controls['name'].setErrors(this.editForm.controls['name'].errors);
        }
      });

    this.editForm.controls['tools'].valueChanges
      .subscribe((response: any) => {
        this.onDefaultToolSelectionChanged(response);
      });

    this.createSubgroupForm.controls['tool'].valueChanges
      .subscribe((response: any) => {
        this.onSubgroupToolSelectionChanged(response);
      });

    this.editForm.controls['primaryclaimspocemail'].valueChanges
      .pipe(
        filter(res => {
          this.editForm.controls['primaryclaimspocemail'].setErrors(this.editForm.controls['primaryclaimspocemail'].errors);
          return res !== null && res.length >= this.minLengthTerm && !this.editForm.controls['primaryclaimspocemail'].invalid
        }),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => {
          this.isLoading = true;
          this.selectedPrimaryClaimSpocFName= "";
          this.selectedPrimaryClaimSpocLName = "";
          this.editForm.controls['primaryclaimspocemail'].markAsTouched({ onlySelf: true });
        }),
        switchMap(value => this.appService.getOktaDetailsByEmail(value!)
          .pipe(
            catchError(error => {
              this.isLoading = false;
              this.editForm.controls['primaryclaimspocemail'].setErrors({ 'employeenotfound': true });
              return "";
            }),
            finalize(() => {
              this.isLoading = false
            }),
          )
        )
      )
      .subscribe((response: any) => {
        this.isLoading = false;
        if(response.aonnetId !=null){            
          this.selectedPrimaryClaimSpocFName = response.firstName;
          this.selectedPrimaryClaimSpocLName = response.lastName;
        } else {
          this.editForm.controls['primaryclaimspocemail'].setErrors({ 'employeenotfound': true });
        }
      });

    this.editForm.controls['secondaryclaimspocemail'].valueChanges
      .pipe(
        filter(res => {
          this.editForm.controls['secondaryclaimspocemail'].setErrors(this.editForm.controls['secondaryclaimspocemail'].errors);
          return res !== null && res.length >= this.minLengthTerm && !this.editForm.controls['secondaryclaimspocemail'].invalid
        }),
        distinctUntilChanged(),
        debounceTime(300),
        tap(() => {
          this.isLoading = true;
          this.selectedSecondaryClaimSpocFName= "";
          this.selectedSecondaryClaimSpocLName = "";
          this.editForm.controls['secondaryclaimspocemail'].markAsTouched({ onlySelf: true });
        }),
        switchMap(value => this.appService.getOktaDetailsByEmail(value!)
          .pipe(
            catchError(error => {
              this.isLoading = false;
              console.log('aonnet id error');   
              this.editForm.controls['secondaryclaimspocemail'].setErrors({ 'employeenotfound': true });
              console.log('aonnet id error', this.editForm.controls['secondaryclaimspocemail'].touched);
              return "";
            }),
            finalize(() => {
              this.isLoading = false
            }),
          )
        )
      )
      .subscribe((response: any) => {
        this.isLoading = false;
        if(response.aonnetId !=null){
          console.log('aonnet id', response.aonnetId);
          this.selectedSecondaryClaimSpocFName = response.firstName;
          this.selectedSecondaryClaimSpocLName = response.lastName;
        } else {
          console.log('aonnet id not found');   
          this.editForm.controls['secondaryclaimspocemail'].setErrors({ 'employeenotfound': true });
          console.log('aonnet id not found', this.editForm.controls['secondaryclaimspocemail'].errors);  
        }
      });
  }

  findClient(){
    if(this.id != null){
      this.isLoading = true;
      this.appService.getClientById(this.id).subscribe((response) => {
        this.client = response;
        this.setCountries();
        this.updateBasicClientFields();
      },(e) => {
        this.isLoading = false;
        this.showSnackbar(e.message);
        console.log('findclient err', e.error);
      });
    }
  }

  setCountries(){
    this.appService.searchCountries(1000, 0, '', 'asc').subscribe((response:any) => {
      this.filteredCountries = response['data'];
      this.updateBasicClientFields();
      this.selectedCountry = this.filteredCountries.filter((country: { id: any; }) => country.id === this.client.countryId)[0];
      this.setTools();
    });
  }

  setTools(){
    this.appService.searchTools(1000, 0, '', this.selectedCountry.id).subscribe((response:any) => {
      if(response['data'].length == 0){
        this.noTools = true;
      }
      this.allTools = response['data'];
      console.log('Got All Tools', this.allTools);
      this.updateBasicClientFields();
      this.initializeToolSelections();
      this.isLoading = false;
    });
  }

  updateBasicClientFields(){
    if(this.client && this.allIndustries && this.allIndustries.length > 0 && this.allTools){
      this.editForm.controls['country'].setValue(`${this.selectedCountry.name} (${this.selectedCountry.code})`);
      this.editForm.controls['name'].setValue(this.client.name);
      this.editForm.controls['type'].setValue(this.client.type);
      this.editForm.controls['spUrl'].setValue(this.client.spSiteUrl ?? '');
      this.editForm.controls['odsClientIds'].setValue(this.client.odsClientIdsCsv);
      
      this.spSiteUrl = this.client.spSiteUrl;
      this.spSiteGetAccessLink = `${environment.aonlineUrl}${environment.getAccessTargetUrl}${this.client.spSiteUrl}`;
      this.createSpSite = this.client.createSpSite;
      this.selectedIndustry = this.allIndustries.filter((industry: { code: any; }) => industry.code == this.client.industry.code)[0];
      
      this.file = null;
      this.fileInputElement.nativeElement.value = '';
      if(this.client.imagePath != null){
        this.imagePath = `${environment.appUrl}${this.client.imagePath}`
      }

      this.isLoading = false;
    }
  }

  initializeToolSelections(){
    if(!this.allTools) return;

    var defaultTools: any = [];
    this.subgroups = [];

    this.client.tools.forEach((tool: { isDefault: boolean; toolCode: string; canDelete: boolean }) =>{
      tool.canDelete = true;
      if(tool.isDefault === true){
        defaultTools.push(tool);
        this.existingDefaultToolCodes.push(tool.toolCode);
      }else{
        this.subgroups.push(tool);
        if(tool.toolCode === this.TOOLCODE_COLLABORATION){
          tool.canDelete = this.pCreateSpSite;
        }
        this.existingSubgroupToolCodes.push(tool.toolCode);
        if(tool.toolCode === this.TOOLCODE_CLAIMS){
          this.setClaimManagers();
        }
      }
    });

    this.potentialSubgroupTools = this.allTools.filter((tool: { id: any; }) => {
      return !(this.client.tools.map( (clientTool: { id: any; }) => clientTool.id)).includes(tool.id);
    });
    this.potentialDefaultTools = this.allTools.filter((tool: { id: any; }) => {
      return !(this.subgroups.map( (clientTool: { id: any; }) => clientTool.id)).includes(tool.id) ;
    }).filter((tool: { toolCode: string; }) => {
      return tool.toolCode !== this.TOOLCODE_CLAIMS ;
    })

    this.onDefaultToolSelectionChanged(defaultTools);

    this.isCollabToolSelectedSubgroup = (this.subgroups.filter((tool: { toolCode: string; }) => tool.toolCode == this.TOOLCODE_COLLABORATION)).length > 0;
    this.isRealEstateToolSelectedSubgroup = (this.subgroups.filter((tool: { toolCode: string; }) => tool.toolCode == this.TOOLCODE_REALESTATE)).length > 0;
    this.isRequestQuoteToolSelectedSubgroup = (this.subgroups.filter((tool: { toolCode: string; }) => tool.toolCode == this.TOOLCODE_REQUESTQUOTE)).length > 0;
    this.isClaimsToolSelectedSubgroup = (this.subgroups.filter((tool: { toolCode: string; }) => tool.toolCode == this.TOOLCODE_CLAIMS)).length > 0;

    this.redrawToolSpecificFields();
  }

  onDefaultToolSelectionChanged(newDefaultTools: any){
    this.selectedDefaultTools = this.allTools.filter((tool: { id: any; }) => {
      return (newDefaultTools.map( (clientTool: { id: any; }) => clientTool.id)).includes(tool.id);
    });
    
    this.isCollabToolSelectedDefault = (this.selectedDefaultTools.filter((tool: { toolCode: string; }) => tool.toolCode == this.TOOLCODE_COLLABORATION)).length > 0;
    this.isRealEstateToolSelectedDefault = (this.selectedDefaultTools.filter((tool: { toolCode: string; }) => tool.toolCode == this.TOOLCODE_REALESTATE)).length > 0;
    this.isRequestQuoteToolSelectedDefault = (this.selectedDefaultTools.filter((tool: { toolCode: string; }) => tool.toolCode == this.TOOLCODE_REQUESTQUOTE)).length > 0;
    this.isClaimsToolSelectedDefault = (this.selectedDefaultTools.filter((tool: { toolCode: string; }) => tool.toolCode == this.TOOLCODE_CLAIMS)).length > 0;

    this.redrawToolSpecificFields();
  }

  redrawToolSpecificFields(){
    this.allTools.forEach((tool: { disabled: boolean; toolCode: string; }) => {
      if(this.pCreateSpSite == false && tool.toolCode === this.TOOLCODE_COLLABORATION){
        tool.disabled = true;
      }else{
        tool.disabled = false;
      }
    });

    let toDisableDefault = [];
    let toDisableSubgroup = [];
    if(this.isCollabToolSelectedDefault == true || this.isCollabToolSelectedSubgroup == true){
      toDisableDefault = this.potentialDefaultTools.filter((tool: { disabled: boolean; toolCode: string; }) => tool.toolCode == this.TOOLCODE_REALESTATE || tool.toolCode == this.TOOLCODE_REQUESTQUOTE);
      toDisableSubgroup = this.potentialSubgroupTools.filter((tool: { disabled: boolean; toolCode: string; }) => tool.toolCode == this.TOOLCODE_REALESTATE || tool.toolCode == this.TOOLCODE_REQUESTQUOTE);
    } else if(this.isRequestQuoteToolSelectedDefault || this.isRealEstateToolSelectedSubgroup || this.isRealEstateToolSelectedDefault || this.isRealEstateToolSelectedSubgroup){
      toDisableDefault = this.potentialDefaultTools.filter((tool: { disabled: boolean; toolCode: string; }) => tool.toolCode == this.TOOLCODE_COLLABORATION);
      toDisableSubgroup = this.potentialSubgroupTools.filter((tool: { disabled: boolean; toolCode: string; }) => tool.toolCode == this.TOOLCODE_COLLABORATION);
      this.editForm.controls["spUrl"].setValidators(Validators.required);
      this.editForm.controls["spUrl"].updateValueAndValidity();
    } else {
      this.editForm.controls["spUrl"].clearValidators();
      this.editForm.controls["spUrl"].updateValueAndValidity();
    }

    toDisableDefault.forEach((tool: { disabled: boolean; toolCode: string; }) => {
      tool.disabled = true;
    });
    toDisableSubgroup.forEach((tool: { disabled: boolean; toolCode: string; }) => {
      tool.disabled = true;
    });

    if(this.isClaimsToolSelectedSubgroup == true || this.isClaimsToolSelectedDefault == true){
      this.editForm.controls["odsClientIds"].setValidators(Validators.required);
      this.editForm.controls["odsClientIds"].updateValueAndValidity();
      this.editForm.controls["primaryclaimspocemail"].setValidators([Validators.required, Validators.email]);
      this.editForm.controls["primaryclaimspocemail"].updateValueAndValidity();
      this.editForm.controls["secondaryclaimspocemail"].setValidators([Validators.required, Validators.email]);
      this.editForm.controls["secondaryclaimspocemail"].updateValueAndValidity();
    }else{
      this.editForm.controls["odsClientIds"].clearValidators();
      this.editForm.controls["odsClientIds"].updateValueAndValidity();
      this.editForm.controls["primaryclaimspocemail"].clearValidators();
      this.editForm.controls["primaryclaimspocemail"].updateValueAndValidity();
      this.editForm.controls["secondaryclaimspocemail"].clearValidators();
      this.editForm.controls["secondaryclaimspocemail"].updateValueAndValidity();
    }
  }

  onSubgroupToolSelectionChanged(selectedSubgroupTools: any){
    if(selectedSubgroupTools){
      this.isCollabToolSelectedSubgroup = selectedSubgroupTools.toolCode === this.TOOLCODE_COLLABORATION;
      this.isClaimsToolSelectedSubgroup = selectedSubgroupTools.toolCode === this.TOOLCODE_CLAIMS;
      this.isRealEstateToolSelectedSubgroup = selectedSubgroupTools.toolCode === this.TOOLCODE_REALESTATE;;
      this.isRequestQuoteToolSelectedSubgroup = selectedSubgroupTools.toolCode === this.TOOLCODE_REQUESTQUOTE;
    }

    this.redrawToolSpecificFields();

    if(this.isClaimsToolSelectedSubgroup || this.isRealEstateToolSelectedSubgroup || this.isRequestQuoteToolSelectedSubgroup){
      this.showingNewFieldsForSubgroupSelection = true;
    }else{
      this.showingNewFieldsForSubgroupSelection = false;
    }

  }

  setClaimManagers(){
    this.appService.getClaimManagersByClientId(this.client.id!).subscribe((response:any) => {
      this.editForm.controls['primaryclaimspocemail'].setValue(response[0].user.email);
      this.editForm.controls['secondaryclaimspocemail'].setValue(response[1].user.email);
      this.selectedPrimaryClaimSpocFName = response[0].user.firstName;
      this.selectedPrimaryClaimSpocLName = response[0].user.lastName;
      this.selectedSecondaryClaimSpocFName = response[1].user.firstName;
      this.selectedSecondaryClaimSpocLName = response[1].user.lastName;
      this.isLoading = false;
    });
  }

  showModal:boolean=false;
  ck1:boolean=false;
  ck2:boolean=false;

  close()
  {
    this.showModal=false;
    console.log(this.ck1,this.ck2);
  }

  onSubmit(): void {
    if(this.showingNewFieldsForSubgroupSelection){
      if (confirm("This will create the selected Sub Group as well. Continue?") == true) {
        //Click the Subgroup button
        document.getElementById('btnCreateSubgroupFormSubmit')?.click();
      }
      return;
    }

    if((this.existingDefaultToolCodes.includes(this.TOOLCODE_COLLABORATION) && !this.isCollabToolSelectedDefault) ||
       (this.existingSubgroupToolCodes.includes(this.TOOLCODE_COLLABORATION) && !this.isCollabToolSelectedSubgroup) )
    {
      this.showModal=true;
    }
    else{
      this.onSubmitFinal();
    }
  }

  onSubmitFinal(){
    if (this.editForm.invalid) {
      return;
    }
    window.scrollTo(0, 0);
    this.updateClient(this.getClientFormData());
    this.showModal=false;
  }

  getClientFormData(){
    const clientFormData = new FormData();
    clientFormData.append('Id', this.client.id);
    clientFormData.append('CountryId', this.client.countryId);
    clientFormData.append('Name', this.editForm.controls['name'].value!);
    clientFormData.append('Type', this.editForm.controls['type'].value!);
    clientFormData.append('IndustryId', this.selectedIndustry.id);
    clientFormData.append('Status', 'true');
    clientFormData.append('Status', 'true');
    clientFormData.append('AccessOn', this.selectedDefaultTools.map((tool:any) => tool.id).join(","));
    clientFormData.append('SpSiteUrl', this.editForm.controls['spUrl'].value!);
    clientFormData.append('OdsClientIdsCsv', this.editForm.controls['odsClientIds'].value!);
    if(this.file){
      clientFormData.append('Image', this.file, this.file?.name);
    }

    if(this.isClaimsToolSelectedDefault || this.isClaimsToolSelectedSubgroup){
      clientFormData.append('ASPEmail', this.editForm.controls['primaryclaimspocemail'].value!);
      clientFormData.append('ASSEmail', this.editForm.controls['secondaryclaimspocemail'].value!); 
    }
    return clientFormData;
  }

  async updateClient(clientFormData: FormData) {
    this.isLoading = true;

    var requests = [this.appService.updateClient(clientFormData)];
    forkJoin(requests).subscribe(
      (results) => {
        this.isLoading = false;
        console.log(results)
        this.showSnackbar("Client updated successfully");
        this.findClient();
      },
      (error) => {
        this.isLoading = false;
        this.showSnackbar(error.message);
      }
    );
  }

  resetForms(){
    this.editForm.reset();
    this.selectedDefaultTools = [];
    this.file = null;
    this.noTools = false;
    this.isCollabToolSelectedDefault = false;

    if(this.createSubgroupFormDirective !== null){
      this.createSubgroupFormDirective.resetForm();
    }
    this.createSubgroupForm.reset();
    this.selectedSubgroupTool = null;
  }

  resetVars(){
    this.filteredCountries = [];
    this.allTools = [];
    this.selectedDefaultTools = [];
    this.potentialDefaultTools = [];
    this.potentialSubgroupTools = [];
    this.subgroups = [];
  }

  resetPage(){
    this.resetForms();
    this.resetVars();
  }

  onFileChange(event: any) {
    this.file = null;
    this.fileErr = "";
    if(event.target.files[0] == null){
      return;
    }
    this.file = event.target.files[0];
    
    let type: string = this.file?.type!;
    if(type !== 'image/png' && type !== 'image/jpeg'){
      this.fileErr = "Invalid file!"
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target?.result as string;
      image.onload = () => {
        const {
          height,
          width
        } = image;
        if (height > 44) {
          this.fileErr = "Height must not exceed 44px.";
          return false;
        }else if(width > 165){
          this.fileErr = "Width must not exceed 165px.";
          return false;
        }
        return true;
      };
    };
  }

  syncAdminsForCountryClicked(){
    this.isLoading = true;
    console.log('selectedCountry', this.selectedCountry);
    let sharepointTask = this.getNewSharepointTask(this.selectedCountry.code, 'SYNC_ADMINS_FOR_COUNTRY');
    this.appService.addSharepointTask(sharepointTask).subscribe((response:any) => {
      this.isLoading = false;
      this.showSnackbar('Admin sync scheduled for country');
    },
    (e) => {
      this.isLoading = false;
      this.showSnackbar(e.message);
    });
  }

  syncAdminsClicked(){
    this.isLoading = true;
    console.log('client', this.client);
    let sharepointTask = this.getNewSharepointTask(this.client.clientId, 'SYNC_ADMINS');
    this.appService.addSharepointTask(sharepointTask).subscribe((response:any) => {
      this.isLoading = false;
      this.showSnackbar('Admin sync scheduled');
    },
    (e) => {
      this.isLoading = false;
      this.showSnackbar(e.message);
    });
  }

  migrateUsersClicked(){
    this.isLoading = true;
    let sharepointTask = this.getNewSharepointTask(this.client.clientId, 'SPSITE_MIGRATE');
    this.appService.addSharepointTask(sharepointTask).subscribe((response:any) => {
      this.isLoading = false;
      this.showSnackbar('Migration scheduled');
    },
    (e) => {
      this.isLoading = false;
      this.showSnackbar(e.message);
    });
  }

  cleanupUsersClicked(){
    this.isLoading = true;
    let sharepointTask = this.getNewSharepointTask(this.client.clientId, 'SPSITE_CLEANUP');
    this.appService.addSharepointTask(sharepointTask).subscribe((response:any) => {
      this.isLoading = false;
      this.showSnackbar('Cleanup scheduled');
    },
    (e) => {
      this.isLoading = false;
      this.showSnackbar(e.message);
    });
  }

  getNewSharepointTask(entityId: string, taskCode: string){
    return {
      EntityId: entityId,
      TaskCode: taskCode,
      Status: 'NEW',
      CreatedBy: this.loggedInUser.id
    }
  }

  showSnackbar(message: string){
    this._snackBar.open(message, 'Close', {
      verticalPosition: 'top',
      horizontalPosition: 'end'
    });
  }

  onCancel() {
    this.location.back();
  }
  
  onSubgroupSubmit(formData: any, formDirective: FormGroupDirective): void {
    if(this.selectedSubgroupTool.toolCode == this.TOOLCODE_CLAIMS){
      if(this.editForm.controls['odsClientIds'].value! == null || this.editForm.controls['odsClientIds'].value!.trim() == ''
        || this.editForm.controls['primaryclaimspocemail'].value! == null || this.editForm.controls['primaryclaimspocemail'].value!.trim() == ''
        || this.editForm.controls['secondaryclaimspocemail'].value! == null || this.editForm.controls['secondaryclaimspocemail'].value!.trim() == ''){
        alert('Please enter ODS Client IDs, Primary and Secondary SPOCs for Claims!');
        return;
      }
    }

    if(this.selectedSubgroupTool.toolCode == this.TOOLCODE_REALESTATE || this.selectedSubgroupTool.toolCode == this.TOOLCODE_REQUESTQUOTE){
      if(this.editForm.controls['spUrl'].value! == null || this.editForm.controls['spUrl'].value!.trim() == ''){
        alert('Please enter Sharepoint Site URL!');
        return;
      }
    }

    if (this.editForm.invalid) {
      return;
    }
    
    this.createSubgroupFormDirective = formDirective;
    this.isLoading = true;
    let subGroup = {
      "Code":this.client.clientId+"-"+this.selectedSubgroupTool.toolCode,
      "Name":this.client.name+" - "+this.selectedSubgroupTool.title,
      "ClientId":this.client.id,
      "ToolId": this.selectedSubgroupTool.id
    }
    
    var requests = [this.appService.addSubgroup(subGroup)];
    if((this.isClaimsToolSelectedDefault || this.isClaimsToolSelectedSubgroup) && this.id != null){
      requests.push(this.appService.updateClaimManagers(this.id, this.editForm.controls['primaryclaimspocemail'].value!, this.editForm.controls['secondaryclaimspocemail'].value!));
    }
    forkJoin(requests).subscribe(
      (results) => {
        this.isLoading = false;
        console.log(results);
        this.showSnackbar("SubGroup created successfully");
        this.findClient();
        this.showingNewFieldsForSubgroupSelection = false;
      },
      (error) => {
        this.isLoading = false;
        console.log(error);
        this.showSnackbar(error.message);
        this.showingNewFieldsForSubgroupSelection = false;
      }
    );

  }

  deleteSubgroup(subgroup: any): void {
    this.isLoading = true;
    this.appService.getSubgroupMemberCount(subgroup.gId)
      .subscribe((data) => {
        if(confirm(`This subgroup has ${data > 0 ? data : 'no'} member${data == 1 ? '' : 's'}. ${subgroup.toolCode == "app-collaboration" ? 'Deletion will also delete the Sharepoint collaboration site for this client.' : ''} Confirm deletion?`)) {
          this.appService.deleteSubgroup(subgroup.gId)
          .subscribe((data) => {
            this.isLoading = false;
            this.showSnackbar("Subgroup deleted successfully");
            this.resetVars();
            this.findClient();
          },(e) => {
            console.log(e);
            this.isLoading = false;
            this.showSnackbar(e.message);
          });
        }else{
          this.isLoading = false;
        }
      },(e) => {
        console.log(e);
        this.isLoading = false;
        this.showSnackbar(e.message);
      });
  }
}
