<!--loader-->
<div *ngIf="isLoading">
    <div class="loaderbox">
        <div class="darkbackground-box"></div>
        <div class="spinnerbox-ic">
            <mat-spinner style="width: 55px;height:55px"></mat-spinner>
        </div>
    </div>
</div>

<div class="jumbotron paral paralsec py-5">
    <div class="container card px-4 py-3">
        <div class="row hdbox-fm my-2">
            <div class="col-md-11 col-sm-12 col-xs-12">
                Edit Client <span *ngIf="client?.clientId"> - {{ client?.name }} ({{ client?.clientId }})</span> 
            </div>
            <div class="col-md-1 col-sm-12 col-xs-12 mt-md-0 mt-sm-3 mt-xs-3">
                <img *ngIf="imagePath" src="{{imagePath}}" class="img-fluid" alt="Responsive image">
            </div>
        </div>
    
        <div class="row my-3">
            <div class="col-md-6 col-sm-12">
    
                <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
    
                    <div class="row">
                        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Country :</div>
                        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                            <mat-form-field class="example-full-width" [style.width.%]="90">
                                <mat-label>Selected Country</mat-label>
                                <input matInput formControlName="country" name="country">
                            </mat-form-field>
                        </div>
                    </div>
            
                    <div class="row">
                        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Client Name :</div>
                        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                            <mat-form-field appearance="outline" class="form-field m-0 p-0" [style.width.%]="90">
                                <mat-label>Enter client name</mat-label>
                                <input matInput formControlName="name" name="name">
                                <div *ngIf="editForm.controls['name'].touched && editForm.controls['name'].invalid">
                                    <mat-error *ngIf="editForm.controls['name'].errors?.['required']">This field is required</mat-error>
                                    <mat-error *ngIf="editForm.controls['name'].errors?.['exists']">Client name already exists</mat-error>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
            
                    <div class="row">
                        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Type :</div>
                        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                            <mat-form-field appearance="outline" class="form-field m-0 p-0" [style.width.%]="90">
                                <mat-label>Client type</mat-label>
                                <mat-select formControlName="type" name="type">
                                    <mat-option *ngFor="let type of optionClientTypes" [value]="type.value">
                                        {{type.text}}
                                    </mat-option>
                                </mat-select>
                                <div *ngIf="editForm.controls['type'].touched && editForm.controls['type'].invalid">
                                    <mat-error *ngIf="editForm.controls['type'].errors?.['required']">This field is required</mat-error>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
            
                    <div class="row">
                        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Industry :</div>
                        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                            <mat-form-field appearance="outline" class="form-field m-0 p-0" [style.width.%]="90">
                                <mat-label>Client's industry</mat-label>
                                <mat-select formControlName="industry" name="industry" [(ngModel)]="selectedIndustry">
                                    <mat-option *ngFor="let industry of allIndustries" [value]="industry">
                                        {{industry.title}}
                                    </mat-option>
                                </mat-select>
                                <div *ngIf="editForm.controls['industry'].touched && editForm.controls['industry'].invalid">
                                    <mat-error *ngIf="editForm.controls['industry'].errors?.['required']">This field is required</mat-error>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
            
                    <div class="row">
                        <div class="col-md-3 col-sm-12 col-xs-3 txt-ad-box text-md-end">Provide Access on :</div>
                        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                            <mat-form-field appearance="outline" class="dropdown-access" [style.width.%]="90">
                                <mat-label>Select Apps</mat-label>
                                <mat-select formControlName="tools" name="tools" [(ngModel)]="selectedDefaultTools" multiple panelClass="input-ad-box dropdown">
                                    <mat-select-trigger>
                                        {{(selectedDefaultTools.length > 0) ? selectedDefaultTools[0].title : ''}}
                                        <span *ngIf="(selectedDefaultTools.length || 0) > 1" class="example-additional-selection">
                                            (+{{(selectedDefaultTools.length || 0) - 1}} {{selectedDefaultTools.length === 2 ? 'other' : 'others'}})
                                        </span>
                                    </mat-select-trigger>
                                    <mat-option *ngFor="let tool of potentialDefaultTools" [value]="tool" [disabled]="tool.disabled">{{tool.title}}</mat-option>
                                </mat-select>
                                <mat-hint *ngIf="noTools">No tools available for selected country</mat-hint>
                                <mat-hint *ngIf="!existingDefaultToolCodes.includes(TOOLCODE_COLLABORATION) && isCollabToolSelectedDefault" class="text-primary">Selecting 'Sharepoint Collaboration' tool will create a new Sharepoint collaboration site for this client.</mat-hint>
                                <mat-hint *ngIf="existingDefaultToolCodes.includes(TOOLCODE_COLLABORATION) && !isCollabToolSelectedDefault" class="text-primary">Removing 'Sharepoint Collaboration' tool will delete the Sharepoint collaboration site for this client.</mat-hint>
                                <mat-hint *ngIf="!noTools && !(!existingDefaultToolCodes.includes(TOOLCODE_COLLABORATION) && isCollabToolSelectedDefault) && !(existingDefaultToolCodes.includes(TOOLCODE_COLLABORATION) && !isCollabToolSelectedDefault)">(Multiple choice field and its values are ACX, GAMS, Shopie, Aon Bond Link )</mat-hint>
                            </mat-form-field>
                            <div *ngIf="noTools" class="mb-3"></div>
                            <div *ngIf="!noTools" class="mb-5"></div>
                        </div>
                    </div>

                    <div class="row pb-3">
                        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end">Upload Logo :<div class="smt-txt">Max 165x44 px, JPEG/PNG</div></div>
                        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                            <div>
                                <input class="form-control upload-file-btn" type="file" (change)="onFileChange($event)" [style.width.%]="90" #inputFile>
                                <mat-error *ngIf="fileErr">{{fileErr}}</mat-error>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="isRealEstateToolSelectedDefault || isRealEstateToolSelectedSubgroup || isRequestQuoteToolSelectedDefault || isRequestQuoteToolSelectedSubgroup">
                        <p class="h6 mt-4">Real Estate / Request Quote Fields</p>
                        <div class="row mt-2">
                            <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Site Url :</div>
                            <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                                <mat-form-field appearance="outline" class="form-field m-0 p-0" [style.width.%]="90">
                                    <mat-label>Enter Site Url</mat-label>
                                    <input matInput formControlName="spUrl" name="spUrl">
                                    <div *ngIf="editForm.controls['spUrl'].touched && editForm.controls['spUrl'].invalid">
                                        <mat-error *ngIf="editForm.controls['spUrl'].errors?.['required']">This field is required</mat-error>
                                    </div>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    
                    <div *ngIf="isClaimsToolSelectedDefault || isClaimsToolSelectedSubgroup">
                        <p class="h6 mt-2" >Global Claims Intake Fields</p>
                        <div class="row mt-2">
                            <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>ODS Client Id/s (CSV) :</div>
                            <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                                <mat-form-field appearance="outline" class="form-field m-0 p-0 mb-2" [style.width.%]="90">
                                    <mat-label>Enter ODS Client Id: eg 1363340 or 1363340,1363341,1363342...</mat-label>
                                    <input matInput formControlName="odsClientIds" name="odsClientIds">
                                    <mat-hint >*eg 1363340 or 1363340,1363341,1363342...</mat-hint>
                                    <div *ngIf="editForm.controls['odsClientIds'].touched && editForm.controls['odsClientIds'].invalid">
                                        <mat-error *ngIf="editForm.controls['odsClientIds'].errors?.['required']">This field is required</mat-error>
                                    </div>
                                </mat-form-field>
                                <br>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Primary SPOC Email :</div>
                            <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                                <mat-form-field appearance="outline" class="form-field m-0 p-0" [style.width.%]="90">
                                    <mat-label>Enter Primary SPOC's Email</mat-label>
                                    <input matInput formControlName="primaryclaimspocemail" name="primaryclaimspocemail">
                                    <mat-hint *ngIf="!editForm.controls['primaryclaimspocemail'].invalid">{{selectedPrimaryClaimSpocFName}} {{selectedPrimaryClaimSpocLName}}</mat-hint>
                                    <div *ngIf="editForm.controls['primaryclaimspocemail'].touched && editForm.controls['primaryclaimspocemail'].invalid">
                                        <mat-error *ngIf="editForm.controls['primaryclaimspocemail'].errors?.['required']">This field is required</mat-error>
                                        <mat-error *ngIf="editForm.controls['primaryclaimspocemail'].errors?.['email']">Invalid email address!</mat-error>
                                        <mat-error *ngIf="editForm.controls['primaryclaimspocemail'].errors?.['employeenotfound']">Employee not found!</mat-error>
                                    </div>
                                </mat-form-field>
                                <br>
                            </div>
                        </div>
                    
                        <div class="row mt-3">
                            <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"><span class="text-danger">* </span>Secondary SPOC Email :</div>
                            <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                                <mat-form-field appearance="outline" class="form-field m-0 p-0" [style.width.%]="90">
                                    <mat-label>Enter Secondary SPOC's Email</mat-label>
                                    <input matInput formControlName="secondaryclaimspocemail" name="secondaryclaimspocemail">
                                    <mat-hint *ngIf="!editForm.controls['secondaryclaimspocemail'].invalid">{{selectedSecondaryClaimSpocFName}} {{selectedSecondaryClaimSpocLName}}</mat-hint>
                                    <mat-error *ngIf="editForm.controls['secondaryclaimspocemail'].touched && !editForm.controls['secondaryclaimspocemail'].errors?.['employeenotfound']">Employee not found!</mat-error>
                                    <div *ngIf="editForm.controls['secondaryclaimspocemail'].touched && editForm.controls['secondaryclaimspocemail'].invalid">
                                        <mat-error *ngIf="editForm.controls['secondaryclaimspocemail'].errors?.['required']">This field is required</mat-error>
                                        <mat-error *ngIf="editForm.controls['secondaryclaimspocemail'].errors?.['email']">Invalid email address!</mat-error>
                                        <mat-error *ngIf="editForm.controls['secondaryclaimspocemail'].errors?.['employeenotfound']">Employee not found!</mat-error>
                                    </div>
                                </mat-form-field>
                                <br>
                            </div>
                        </div>

                    </div>
    
                    <div class="row mt-3">
                        <div class="col-md-3 col-sm-3 col-xs-3 txt-ad-box text-md-end"></div>
                        <div class="col-md-9 col-sm-10 col-xs-10 input-ad-box">
                            <button type="submit" class="svn-btn" [disabled]="this.editForm.invalid || fileErr">Submit</button>
                            <button (click)="onCancel()" type="button" class="cn-btn">Cancel</button>
                        </div>
                    </div>
    
                </form>
    
            </div>
    
            <div class="col-md-6 col-sm-12">
                
                <div class="row">
    
                    <div class="row" *ngIf="pCreateSpSite && createSpSite && spSiteUrl">
                        <div class="col-md-12 col-sm-12 col-xs-12 txt-ad-box"><p class="h5 pb-1">Sharepoint Site & Actions</p></div>
                        <div class="col-md-12 col-sm-12 col-xs-12 txt-ad-box" *ngIf="spSiteUrl">
                            <button type="button" class="btn btn-success btn-sm m-1" (click)="syncAdminsForCountryClicked()" *ngIf="isSuperAdmin===true">Sync Admins For Country</button>
                            <button type="button" class="btn btn-success btn-sm m-1" (click)="syncAdminsClicked()">Sync Admins</button>
                            <button type="button" class="btn btn-success btn-sm m-1" (click)="migrateUsersClicked()">Migrate Users</button>
                            <button type="button" class="btn btn-danger btn-sm m-1" (click)="cleanupUsersClicked()">Cleanup Users</button>
                        </div>
                        
                        <div class="col-md-12 col-sm-12 col-xs-12 m-2 mt-3" *ngIf="spSiteUrl">Site URL: <a href="{{spSiteGetAccessLink}}" target="_blank">{{spSiteUrl}}</a></div>
                        <hr class="mt-2">
                    </div>
    
                    <p class="h5 pb-2">Create New Subgroup</p>
                    <form [formGroup]="createSubgroupForm" #formDirective="ngForm" (ngSubmit)="onSubgroupSubmit(createSubgroupForm, formDirective)" class="form-inline">
                        <div class="row">
                            <div class="col-md-8 col-sm-10 col-xs-10 input-ad-box">
                                <mat-form-field appearance="outline" class="form-field m-0 p-0" [style.width.%]="100">
                                    <mat-label>Select Tool</mat-label>
                                    <mat-select formControlName="tool" name="tool" [(ngModel)]="selectedSubgroupTool">
                                        <mat-option *ngFor="let tool of potentialSubgroupTools" [value]="tool" [disabled]="tool.disabled">{{tool.title}}</mat-option>
                                    </mat-select>
                                    <mat-hint  *ngIf="isCollabToolSelectedSubgroup && !spSiteUrl" class="text-primary">Selecting 'Sharepoint Collaboration' tool will create a new Sharepoint collaboration site for this client.</mat-hint>
                                    <div *ngIf="createSubgroupForm.controls['tool'].touched && createSubgroupForm.controls['tool'].invalid">
                                        <mat-error *ngIf="createSubgroupForm.controls['tool'].errors?.['required']">This field is required</mat-error>
                                    </div>
                                </mat-form-field>
                            </div>
    
                            <div class="col-md-4 col-sm-10 col-xs-10 input-ad-box">
                                <button type="submit" class="btn btn-dark align-middle" [disabled]="this.createSubgroupForm.invalid" id="btnCreateSubgroupFormSubmit">Create Subgroup</button>
                            </div>
    
                        </div>
                        <div *ngIf="isCollabToolSelectedSubgroup && !spSiteUrl" class="mb-5"></div>
                    </form>
    
                    <div class="col-md-12 col-sm-12 col-xs-12 txt-ad-box" *ngIf="subgroups.length > 0"><p class="h5 pb-1">Subgroups</p></div>
                    <table class="table table-striped mx-2" *ngIf="subgroups.length > 0">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Tool</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let subgroup of subgroups; index as i">
                            <th scope="row">{{i+1}}</th>
                            <td>{{subgroup.groupName}}</td>
                            <td>{{subgroup.title}}</td>
                            <td class="align-top text-center">
                              <button *ngIf="subgroup.canDelete" type="button" class="btn-close mt-1" aria-label="Close" (click)="deleteSubgroup(subgroup)"></button>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                </div>
        
            </div>
    
        </div>
    </div>
</div>
  
<!-- The Modal -->
<div *ngIf="showModal" id="myModal" class="modal">

    <!-- Modal content -->
    <div class="modal-content">
        <img style="width:15%" src="/assets/images/warning.png">
        <span style="font-weight: 700;">Delete site in SharePoint</span>
      <br>
        <p>This wil destroy all the documents and subsites of this SharePoint site!</p>      
      <p>You are about to delete the site of<span style="font-weight: 700;"*ngIf="client?.clientId"> {{ client?.name }} ({{ client?.clientId }})</span></p>
      
      <p>Please confirm:</p>
      <div>
      <input type="checkbox" id="check1" name="check1" [(ngModel)]="ck1" [checked]="ck1">
      <label for="check1"> If the site contains client or Aon data please confirm you have the Archive Zip file of this place in order to send to the approriate Aon team</label>
      </div>
      <div>
      <input type="checkbox" id="check2" name="check2" [(ngModel)]="ck2" [checked]="ck2">
      <label for="check2"> You are aware that all the data in this Collaboration site will be lost and you are sure you want to do this.</label>
      </div>
      <div _ngcontent-hnj-c176="" class="col-md-9 col-sm-10 col-xs-10 input-ad-box mt-3">
        <button *ngIf="ck1 && ck2" type="submit" class="svn-btn" (click)="onSubmitFinal()">Delete</button>
        <button  type="button" (click)="close()" class="cn-btn">Cancel</button>
    </div>
      
    </div>
  
</div>